
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MultileveltestCoverPlaceholder',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

});
