
import {
  defineComponent, PropType, toRefs, computed,
} from 'vue';

import QuizCoverPlaceholder from './QuizCoverPlaceholder.vue';
import MultileveltestCoverPlaceholder from './MultileveltestCoverPlaceholder.vue';
import MulticontentCoverPlaceholder from './MulticontentCoverPlaceholder.vue';
import ScormCoverPlaceholder from './ScormCoverPlaceholder.vue';
import UnknownCoverPlaceholder from './UnknownCoverPlaceholder.vue';

import { IAtom, AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'AtomCoverPlaceholder',

  components: {
    QuizCoverPlaceholder,
    MultileveltestCoverPlaceholder,
    MulticontentCoverPlaceholder,
    ScormCoverPlaceholder,
    UnknownCoverPlaceholder,
  },

  props: {
    atomType: {
      type: String as PropType<IAtom['atomName']>,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { atomType } = toRefs(props);

    const component = computed(() => {
      switch (atomType.value) {
        case AtomType.QUIZ:
          return QuizCoverPlaceholder;
        case AtomType.MULTILEVELTEST:
          return MultileveltestCoverPlaceholder;
        case AtomType.MULTICONTENT:
          return MulticontentCoverPlaceholder;
        case AtomType.SCORM:
          return ScormCoverPlaceholder;
        default:
          return UnknownCoverPlaceholder;
      }
    });

    return {
      component,
    };
  },
});
